<template lang="html" src="./privateSupplierInformation.template.vue"></template>

<style lang="scss" src="./privateSupplierInformation.scss"></style>

<script>

const i18nData = require('./privateSupplierInformation.i18n');

export default {
  name: 'PrivateSupplierInformation',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  props: {
    /**
     * Supplier object whose information should be displayed
     */
    supplier: {
      type: Object,
      required: true
    },
    /**
     * Whether the component should display a button allowing to show the supplier payment information
     */
    displayPaymentInformation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      paymentInformationDialog: false
    };
  },
  created() {
  },
  methods: {
    openPaymentInformationDialog() {
      this.paymentInformationDialog = true;
    },
    closePaymentInformationDialog() {
      this.paymentInformationDialog = false;
    }
  },
};
</script>

<template lang="html" src="./pageOrder.template.vue"></template>

<style lang="scss" src="./pageOrder.scss"></style>

<script>
import {
  ApiErrorParser,
  CancelOrder,
  DeliveryTrackingV2,
  StripePayment,
  OrderStepperV2,
  BeelseQuote,
} from '@cloudmanufacturingtechnologies/portal-components';
import OrderValidation from '../../components/orderValidation/OrderValidation';
import Hotjar from '@hotjar/browser';
import SupportEmail from '../../components/supportEmail/SupportEmail';

const i18nData = require('./pageOrder.i18n');

export default {
  name: 'PageOrder',
  components: {
    BeelseQuote,
    CancelOrder,
    DeliveryTrackingV2,
    SupportEmail,
    StripePayment,
    OrderStepperV2,
    OrderValidation,
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      order: null,
      pdfData: null,
      paymentIntent: null,
      loadingPdf: false,
      wizardItems: [],
      step: 1,
      disabled: true,
      deliveryTrackingInline: false,
      deliveryTrackingFormMode: null,
      deliveryTracking: null,
      deliveryTrackingComplete: false,
      deliveryTrackingIdsURL: null,
      validationStatus: false,
      modalPayment: false,
      newPaymentMethod: true,
      choicePayment: 1,
      savedPaymentMethods: [],
      cardSelection: null,
      checkboxSaveCard: false,
      errorMessage: null,
      showAlert: true,
      loading: false,
      paymentSuccess: false,
    };
  },
  created() {
    this.wizardItems = [
      this.$t('PAYMENT'),
      this.$t('SCHEDULED'),
      this.$t('MANUFACTURING'),
      this.$t('MANUFACTURED'),
      this.$t('DELIVERY'),
      this.$t('DELIVERED'),
    ];
    this.getOrder();
  },
  methods: {
    getOrder() {
      /**
       * GET ORDER
       */
      this.$apiInstance
        .getBrandOrder(
          this.$route.params.brandUUID,
          this.$route.params.orderUUID
        )
        .then(
          (brandOrderData) => {
            this.order = brandOrderData;
            /**
             * Get DeliveryTracking informations
             */
            if (
              this.order.quote.dispatch[0] &&
              this.order.quote.dispatch[0].deliveryTracking &&
              this.order.quote.dispatch[0].deliveryTracking.length ===
              this.order.quote.dispatch[0].packageNumber
            ) {
              this.deliveryTracking = this.order.quote.dispatch[0].deliveryTracking;
              this.validationStatus = true;
              this.deliveryTrackingFormMode = 'VIEWER';
              this.deliveryTrackingInline = true;
              let trackingIdsURL = '';
              for (const key in this.deliveryTracking) {
                const deliveryTracking = this.deliveryTracking[key];
                if (deliveryTracking.trackingNumber) {
                  trackingIdsURL += `${deliveryTracking.trackingNumber},`;
                }
              }
              trackingIdsURL = trackingIdsURL.slice(0, -1);
              this.deliveryTrackingIdsURL = trackingIdsURL;
            } else {
              this.validationStatus = false;
            }

            /**
             * Check payment status
             */
            if (this.order.paymentStatus !== 'PAID') {
              /**
               * GET PAYMENT METHODS
               */
              this.getSavedPaymentMethods();
              this.$apiInstance
                .getBrandOrderPayment(
                  this.$route.params.brandUUID,
                  this.$route.params.orderUUID
                )
                .then((paymentIntent) => {
                  this.paymentIntent = paymentIntent;
                  if (
                    this.paymentIntent.status === 'succeeded' &&
                    this.order.paymentStatus !== 'PAID'
                  ) {
                    this.checkPaymentStatus(paymentIntent);
                  } else {
                    this.currentOrderStatus();
                  }
                });
            } else {
              /**
               * GET ORDER STATUS
               */
              this.currentOrderStatus();
            }
          },
          (error) => {
            /**
             * ERROR GET ORDER
             */
            ApiErrorParser.parse(error);
          }
        );
    },
    getOrderPDF() {
      if(!this.pdfData) {
        this.loadingPdf = true;
        /**
         * GET ORDER PDF
         */
        this.$apiInstance
          .getBrandOrderPDF(this.order.brand, this.order._id)
          .then(
            (pdf) => {
              this.pdfData = pdf.data;
              this.disabled = false;
              this.$refs.invoicePDFRef.href = `data:application/pdf;base64,${this.pdfData}`;
              setTimeout(() => {
                this.$refs.invoicePDFRef.click();
              }, 250);
              
            },
            (error) => {
              /**
               * ERROR GET QUOTE PDF
               *
               * Component BeelseNotifications used
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
              this.disabled = true;
            }
          )
          .finally(() => {
            this.loadingPdf = false;
          });
      } else {
        this.$refs.invoicePDFRef.click();
      }
    },
    getSavedPaymentMethods() {
      this.savedPaymentMethods = [];
      this.loadingPayments = true;
      this.$apiInstance
        .getBrandDefaultPaymentMethod(this.$appConfig.currentBrand)
        .then(
          (paymentMethodDefaultData) => {
            this.paymentMethodDefault = paymentMethodDefaultData;
            this.$apiInstance
              .getBrandPaymentMethods(this.$appConfig.currentBrand)
              .then(
                (paymentMethodData) => {
                  const now = new Date();
                  for (const paymentMethod of paymentMethodData) {
                    const card = paymentMethod.card;
                    if (
                      card.exp_year > now.getFullYear() ||
                      (card.exp_year === now.getFullYear() &&
                        card.exp_month >= now.getMonth() + 1)
                    ) {
                      this.savedPaymentMethods.push({
                        cardInfo: paymentMethod,
                        cardText: `******** ${card.last4} - Exp : ${card.exp_month}/${card.exp_year}`,
                      });
                    }
                  }
                  if (this.savedPaymentMethods.length > 0) {
                    this.newPaymentMethod = false;
                    this.choicePayment = 0;
                    this.cardSelection = this.savedPaymentMethods[0].cardInfo;
                  }
                },
                (error) => {
                  /**
                   * Component BeelseNotifications used
                   */
                  this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
                }
              )
              .finally(() => {
                this.loadingPayments = false;
              });
          },
          (error) => {
            ApiErrorParser.parse(error);
            this.loadingPayments = false;
          }
        );
    },
    currentOrderStatus() {
      if (
        this.order.paymentStatus !== 'PAID' &&
        this.order.paymentMethod === 'card'
      ) {
        this.step = 0;
      } else if (
        this.order.paymentMethod !== 'card' ||
        this.order.paymentStatus === 'PAID'
      ) {
        this.step = 1;
      }

      if (this.order.quote.dispatch[0].status === 'MANUFACTURING') {
        this.step = 2;
      }
      if (this.order.quote.dispatch[0].status === 'MANUFACTURED') {
        this.step = 3;
      }
      if (this.order.quote.dispatch[0].status === 'DELIVERY') {
        this.step = 4;
      }
      if (
        this.order.status === 'DELIVERED' &&
        this.order.quote.dispatch[0].status === 'DELIVERED'
      ) {
        this.step = 5;
      }
    },
    linkToDeliveryTrackingWebsite() {
      this.deliveryTrackingUrl = this.$refs.myDeliveryTracking.getURL();
      window.open(this.deliveryTrackingUrl);
    },
    changePaymentMethod() {
      this.resetAlert();
      this.newPaymentMethod = this.choicePayment === 1;
    },
    payOrder() {
      this.loading = true;
      const stripe = Stripe(this.$stripeConfig.pk, this.$userLocale);
      const self = this;
      this.resetAlert();
      stripe
        .confirmCardPayment(this.paymentIntent.client_secret, {
          payment_method: this.cardSelection.id,
        })
        .then(function(result) {
          if (result.paymentIntent) {
            self.checkPaymentStatus(result.paymentIntent);
          } else {
            if (result.error) {
              self.errorMessage = result.error.message;
            } else {
              self.errorMessage = self.$t('ErrorVerifyPaymentSession');
            }
          }
        })
        .finally(() => {
          self.loading = false;
        });
    },
    checkPaymentStatus(paymentIntent) {
      if (paymentIntent.status === 'succeeded') {
        this.paymentSuccess = true;
        this.$apiInstance.updateBrandOrderPaymentStatus(
          this.$route.params.brandUUID,
          this.$route.params.orderUUID
        ).then(() => {

        }, (err) => {
          this.$notification.notify('ERROR', ApiErrorParser.parse(err));
        }).finally(() => {
          this.getOrder();
        });
        /**
         * Send Hot Jar Survey
         */
        Hotjar.stateChange(`/${document.location.hash}?p=true`);
      }
    },
    resetAlert() {
      this.errorMessage = null;
      this.showAlert = true;
    },
    cancelOrder() {
      this.$apiInstance.cancelBrandOrder(
        this.$route.params.brandUUID,
        this.$route.params.orderUUID
      ).then(() => {
        this.$notification.notify('SUCCESS', this.$t('OrderCanceledSuccessfully'));
      }).catch(err => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(err));
      }).finally(() => {
        this.$refs.cancelOrderRef.closeDialog();
        this.getOrder();
      });
    },
  },
};
</script>

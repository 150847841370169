<template lang="html" src="./orderValidation.template.vue"></template>

<style lang="scss" src="./orderValidation.scss"></style>

<script>

import PrivateSupplierInformation from '../privateSupplierInformation/PrivateSupplierInformation';
import BankTransferInstructions from '../bankTransferInstructions/BankTransferInstructions.vue';

const i18nData = require('./orderValidation.i18n');

export default {
  name: 'OrderValidation',
  components: {
    PrivateSupplierInformation,
    BankTransferInstructions
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    paymentIntentReady: {
      type: Boolean,
      default: false
    },
    privateSupplier: {
      type: Object,
      default: null
    }
  },
  watch: {
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    openPaymentModal() {
      this.$emit('openPaymentModal');
    },
  }
};
</script>

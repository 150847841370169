var render = function render(){
  var _vm$$appConfig, _vm$$appConfig$fundin;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "component-orderValidation"
  }, [_c('v-card', [_c('v-card-text', [_c('div', {
    staticClass: "text-h6 black--text mb-4"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "info",
      "small": ""
    }
  }, [_vm._v(" fas fa-box-open ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Order')) + " - " + _vm._s(_vm.order.invoiceNumber) + " ")])], 1), _c('v-divider'), _c('v-simple-table', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('tbody', [_c('tr', [_c('td', {
          staticClass: "col-item-price-ht font-weight-medium",
          attrs: {
            "width": "70%"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('NetTotalVATExcluded')) + " ")]), _c('td', {
          staticClass: "col-item-total-ht text-right"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.order.quote.selling.totalAmountWithoutVAT, "currency", "fr")) + " ")])]), _c('tr', [_c('td', {
          staticClass: "pl-8 text-caption custom-dense-td"
        }, [_vm._v(" " + _vm._s(_vm.$t('IncludingDeliveryOf')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.order.quote.selling.deliveryPrice, "currency", "fr")) + " ")])]), _vm.order.quote.selling.totalDiscount !== '0' ? _c('tr', [_c('td', {
          staticClass: "pl-8 text-caption custom-dense-td"
        }, [_vm._v(" " + _vm._s(_vm.$t('IncludingDiscountOf')) + " ")]), _c('td', {
          staticClass: "custom-dense-td text-right"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.order.quote.selling.totalDiscount, "currency", "fr")) + " ")])]) : _vm._e(), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('TotalVAT')) + " ")]), _c('td', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.order.quote.selling.vatTotal, "currency", "fr")) + " ")])]), _c('tr', [_c('td', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm.$t('TotalAmountVATIncluded')) + " ")]), _c('td', {
          staticClass: "text-right text-h6"
        }, [_vm._v(" " + _vm._s(_vm.$n(_vm.order.quote.selling.totalAmount, "currency", "fr")) + " ")])])])];
      },
      proxy: true
    }])
  })], 1)], 1), _vm.order.billing && (_vm.order.public || _vm.order.quote.dispatch[0].onlinePayment) && _vm.order.paymentStatus !== 'PAID' && _vm.order.status !== 'CANCELED' ? _c('v-card', {
    staticClass: "mt-2"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('payment')))]), _c('v-card-text', [(!_vm.order.public || _vm.order.paymentMethod === 'card') && _vm.order.paymentStatus !== 'PAID' && _vm.order.status !== 'CANCELED' ? _c('v-btn', {
    staticClass: "text-none white--text",
    attrs: {
      "color": "orange",
      "width": "100%",
      "disabled": !_vm.paymentIntentReady
    },
    on: {
      "click": _vm.openPaymentModal
    }
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-credit-card ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('PayByCard')) + " ")])], 1) : _vm._e(), ((_vm$$appConfig = _vm.$appConfig) === null || _vm$$appConfig === void 0 ? void 0 : (_vm$$appConfig$fundin = _vm$$appConfig.fundingInstructions) === null || _vm$$appConfig$fundin === void 0 ? void 0 : _vm$$appConfig$fundin.length) > 0 ? _c('v-divider', {
    staticClass: "my-4"
  }) : _vm._e(), _c('BankTransferInstructions', {
    attrs: {
      "cols": "12"
    }
  })], 1)], 1) : _vm._e(), _vm.privateSupplier && !_vm.order.public ? _c('PrivateSupplierInformation', {
    attrs: {
      "supplier": _vm.privateSupplier,
      "display-payment-information": !_vm.order.public && _vm.order.billing && !_vm.order.quote.dispatch[0].onlinePayment && !!_vm.privateSupplier.paymentConfiguration
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
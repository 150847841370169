<template>
  <v-row>
    <v-col
      v-for="(fundingInstruction, index) in this.fundingInstructions"
      :key="index"
      :cols="cols"
    >
      <v-card elevation="2">
        <v-card-title>
          <v-icon class="mr-2">
            fas fa-university
          </v-icon> IBAN
          <v-spacer></v-spacer>
          <flag :iso="fundingInstruction?.bank_transfer?.country"></flag>
        </v-card-title>
        <v-card-text v-if="fundingInstruction?.bank_transfer?.financial_addresses">
          <div v-for="(financialAddresse, index) in fundingInstruction?.bank_transfer?.financial_addresses" :key="index">
            <table v-if="financialAddresse?.iban">
              <tr>
                <td class="pr-3">
                  {{ $t('ibanBic') }}
                </td><td>{{ financialAddresse?.iban?.bic }}</td>
              </tr>
              <tr>
                <td class="pr-3">
                  IBAN
                </td><td>{{ financialAddresse?.iban?.iban }}</td>
              </tr>
              <tr>
                <td class="pr-3">
                  {{ $t('ibanCountry') }}
                </td><td>{{ financialAddresse?.iban?.country }}</td>
              </tr>
            </table>
            <v-alert
              v-else
              outlined
              text
              type="error"
            >
              {{ $t('pleaseContactSupport') }}
            </v-alert>
            <v-divider class="my-2" v-if="fundingInstruction?.bank_transfer?.financial_addresses.length > 1 && index !== fundingInstruction?.bank_transfer?.financial_addresses.length - 1"></v-divider>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-alert
            outlined
            text
            type="error"
          >
            {{ $t('pleaseContactSupport') }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  EventBus,
  ApiErrorParser,
} from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./bankFundingInstructions.i18n.json');

export default {
  name: 'BankTransferInstructions',
  components: {},
  props: {
    cols: {
      type: String,
      default: '4'
    },
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      fundingInstructions: null
    };
  },
  created() {
    if (!this.$appConfig.brand) {
      EventBus.$emit('reloadBrand');
    }

    this.getBrandStripeFundingInstructions();
  },
  methods: {
    getBrandStripeFundingInstructions() {
      /**
       * GET SAVED PAYMENT METHODS
       */
      this.$apiInstance.getBrandStripeFundingInstructions(this.$appConfig.currentBrand)
        .then(
          (fundingInstructionsData) => {
            this.fundingInstructions = fundingInstructionsData;
          },
          (error) => {
            this.fundingInstructions = [];
          }
        );
    }
  },
};
</script>

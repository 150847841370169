var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-2 component-privateSupplierInformation"
  }, [_c('v-card-title', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "privateSupplier"
    }
  }, [_vm._v(" fas fa-industry ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('SupplierInformation')) + " ")])], 1), _c('v-card-text', [_c('div', {
    staticClass: "font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.supplier.name) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.supplier.address.streetAddress) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.supplier.address.zipCode) + " " + _vm._s(_vm.supplier.address.city) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t(`countries.${_vm.supplier.address.country}`)) + " ")]), _vm.supplier.address.phoneNumber ? _c('div', [_vm._v(" " + _vm._s(_vm.supplier.address.phoneNumber) + " ")]) : _vm._e(), _vm.displayPaymentInformation ? _c('v-divider', {
    staticClass: "my-2"
  }) : _vm._e(), _vm.displayPaymentInformation ? _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info",
      "width": "100%"
    },
    on: {
      "click": _vm.openPaymentInformationDialog
    }
  }, [_c('v-icon', {
    staticClass: "mr-4"
  }, [_vm._v(" fas fa-money-check-alt ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('SupplierPaymentInformation')) + " ")])], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    model: {
      value: _vm.paymentInformationDialog,
      callback: function ($$v) {
        _vm.paymentInformationDialog = $$v;
      },
      expression: "paymentInformationDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('SupplierPaymentInformation')) + " - " + _vm._s(_vm.supplier.name) + " ")]), _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "readonly": "",
      "outlined": ""
    },
    model: {
      value: _vm.supplier.paymentConfiguration,
      callback: function ($$v) {
        _vm.$set(_vm.supplier, "paymentConfiguration", $$v);
      },
      expression: "supplier.paymentConfiguration"
    }
  })], 1), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.closePaymentInformationDialog
    }
  }, [_c('v-icon', {
    staticClass: "mr-4"
  }, [_vm._v(" fas fa-times ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('Close')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }